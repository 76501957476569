import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  FeedbackToast,
  ToastProps,
  ToastColor,
} from "@pushpress/shared-components";

export interface ToastContextValue {
  setToast: (toast: ToastProps) => void;
  setSuccessToast: (toast: Omit<ToastProps, "status">) => void;
  setErrorToast: (toast: Omit<ToastProps, "status">) => void;
}

interface ToastProviderProps {
  children?: ReactNode;
}

const ToastContext = createContext<ToastContextValue | undefined>(undefined);

const ToastProvider = (props: ToastProviderProps) => {
  const { children } = props;

  const [toast, setToast] = useState<ToastProps>();

  const setSuccessToast = useCallback((p: Omit<ToastProps, "status">) => {
    setToast({
      ...p,
      // @ts-expect-error TODO: fix this
      status: ToastColor.Success,
    });
  }, []);

  const setErrorToast = useCallback((p: Omit<ToastProps, "status">) => {
    setToast({
      ...p,
      // @ts-expect-error TODO: fix this
      status: ToastColor.Error,
    });
  }, []);

  const contextValue = useMemo(() => {
    return {
      setToast,
      setSuccessToast,
      setErrorToast,
    };
  }, [setSuccessToast, setErrorToast]);

  return (
    <ToastContext.Provider value={contextValue}>
      {/* @ts-expect-error TODO: fix this */}
      <FeedbackToast toastData={toast} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const contextValue = useContext(ToastContext);
  if (!contextValue) {
    throw new Error("useToast needs to be used inside a ToastProvider.");
  }
  return contextValue;
};

export default ToastProvider;
